import React, { useState,useEffect } from "react";

import {
    Container,
    Row,
    Col,
    Card,
    Table,
    Form,
    InputGroup,
    Button
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from '@mui/material/Box';
import "../css/spacewall.css"
import "../css/spacewallupload.css"
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { marketinggifts } from "../../utils/apiServices";
import config from "../../../coreFiles/config";

import axios from "axios";
import { useDispatch, useSelector } from "react-redux";


const LoyaltyItems = () => {
  const AuthToken = useSelector((state) => state.auth.AuthToken);
        const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
    const [marketinggift,setMarketinggifts] = useState([]) 
useEffect(()=>{
getMarketinggifts()
},[])

const getMarketinggifts=async()=>{
try{

    let reuslt = await marketinggifts({},AuthToken)
    setMarketinggifts(reuslt.data.data)
}catch(error){
    console.log(error)
}
}

const downloadMethod=async(e,imageName)=>{
    e.preventDefault();
    console.log("downloadMethod")
    try{
        const response = await axios.post(`${config.ApiURL}marketinggiftDownload`, { fileName: imageName }, {
            responseType: 'blob' // Specify responseType as 'blob' to receive binary data
          });
    
          if (response.status === 200) {
            // Create a temporary URL for the PDF blob
            const url = window.URL.createObjectURL(new Blob([response.data]));
            
            // Create a temporary link element
            const a = document.createElement('a');
            a.href = url;
            a.download = imageName.replace('.jpg', '.pdf','.png'); // Set the download attribute to the PDF file name
            a.click();
            
            // Clean up by revoking the temporary URL
            window.URL.revokeObjectURL(url);
          } else {
            console.error('Failed to download PDF:', response.statusText);
          }

    }catch(error){
        console.log(error)
    }
}
    return (
        <>
            <Box sx={{ display: '-webkit-box' }} className="dashboard">
                <Sidebar />
                <Box component="main" sx={{ flexGrow: 1, p: 3 }} style={{ marginTop: '74px', }} className="text-white mainBox">
                    <Row>

                        <Col lg={12} className="">

                            <div className="heading mb-2">
                                <h5 className="text-uppercase mb-0">Marketing Gift</h5>
                            </div>
                            <Row>
                                {marketinggift.length>0 && 
                                marketinggift.map((item)=>(<Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <h4 className="fw-lighter text-uppercase">{item.name}</h4>
                                                <img src={`${config.ApiURL}marketinggift/${item.image}`}/>
                                            </div>
                                        </div>
                                            <div className="">
                                                
                                                <Button variant="outline-primary" className="h-auto ml-1 me-3 Downloadbtn" onClick={e=>{downloadMethod(e,item.image)}}><MdOutlineFileDownload fill="#E1B028"/>&nbsp; Download Design</Button>
                                               
                                            </div>
                                    </Card>
                                </Col>))}
                            
                            </Row>
                        </Col>

                    </Row>
                    {/* <Row className="mt-4">

                        <Col lg={12} className="">
                        <Row>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <h4 className="fw-lighter text-uppercase">Cap</h4>
                                            </div>
                                        </div>
                                            <div className="">
                                                
                                                <Button variant="outline-primary" className="h-auto ml-1 me-3 Downloadbtn"><MdOutlineFileDownload fill="#E1B028"/>&nbsp; Download Design</Button>
                                               
                                            </div>
                                    </Card>
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <h4 className="fw-lighter text-uppercase">NOTEBOOK</h4>
                                            </div>
                                        </div>
                                        <div>
                                        <div className="">
                                                
                                                <Button variant="outline-primary" className="h-auto ml-1 me-3 Downloadbtn"><MdOutlineFileDownload fill="#E1B028"/>&nbsp; Download Design</Button>
                                               
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                                <Col lg={4} className="mb-4">
                                    <Card className="overflow-hidden">
                                        <div className="uploadImage mb-3">
                                            <div>
                                                <h4 className="fw-lighter text-uppercase">BOTTLE</h4>
                                            </div>
                                        </div>
                                        <div className="">
                                                
                                                <Button variant="outline-primary" className="h-auto ml-1 me-3 Downloadbtn"><MdOutlineFileDownload fill="#E1B028"/>&nbsp; Download Design</Button>
                                               
                                            </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>

                    </Row> */}
                </Box>
            </Box>


        </>
    );
};

export default LoyaltyItems;
