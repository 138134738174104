import React, { useState, useEffect } from "react";

import {
  Container,
  Nav,
  Navbar,
  NavDropdown,
  Form,
  Offcanvas,
  Modal,
  Row,
  Col,
  Button,
  Card,
  Table,
  Pagination,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import { purchasePlan, depositInWallet,depositInWalletPif } from "../../Web3/Connector";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import {
  dashboardDetails,
  getDirectReferralsByIdApi,
  activateUser,
  piWalleBalance
} from "../../utils/apiServices";
import { TailSpin } from "react-loader-spinner";
import { useAccount } from "wagmi";
import Web3ModalProvider from "../../ConnectWallet/Web3ModalProvider";

const Spacenetwork = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const AuthToken = useSelector((state) => state.auth.AuthToken);

  const [dashboardDetail, setDashboardDetail] = useState([]);
  const [directReferralsDetail, setDirectReferralsDetail] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [activateUserId, setActivateUserId] = useState("");
  const [pifBalance, setPifBalance] = useState(0);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [currentItems, setCurrentItems] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [loader, setLoader] = useState(false);

  const [showPifWallet,setShowPifWallet] = useState(false)

  const [activateUserAddress,setActivateUserAddress] = useState('')
  let indexOfLastItem;
  let indexOfFirstItem;
  const [usdtAmount, setUsdtAmount] = useState("");
  const [usdtAmountErrorMessage, setUsdtAmountErrorMessage] = useState("");

  const { isConnected, address } = useAccount();
  // const { isOpen, open, close, setDefaultChain } = useWeb3Modal();
  const _useWeb3ModalProvider = Web3ModalProvider();

  const handleClose = () => {
    setShowModal(false);
    setActivateUserId("");
    setShowPifWallet(false)
    setUsdtAmount("")
    setUsdtAmountErrorMessage("")
  };

  const handleOpenPifWalletDeposit = () => {
    setShowPifWallet(true)
  };

  useEffect(() => {
    fetchDashboardDetails();
    getDirectReferrals();
    fetchPifWalletBalance();
  }, []);


  const fetchDashboardDetails = async () => {
    // console.log("fetchDashboardDetails");
    try {
      let result = await dashboardDetails(loginDetails,AuthToken);
      if (result.data.success) {
        setDashboardDetail(result.data.data);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const getDirectReferrals = async () => {
    console.log("getDirectReferrals");
    try {
      let result = await getDirectReferralsByIdApi(loginDetails,AuthToken);
      if (result.data.success) {
        
        setDirectReferralsDetail(result.data.data);
        setTotalPages(Math.ceil(result.data.data.length / itemsPerPage));
        indexOfLastItem = currentPage * itemsPerPage;
        indexOfFirstItem = indexOfLastItem - itemsPerPage;
        setCurrentItems(result.data.data.slice(indexOfFirstItem, indexOfLastItem));
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const fetchPifWalletBalance = async () => {
    console.log("fetchPifWalletBalance");
    try {
      let result = await piWalleBalance(loginDetails,AuthToken);
      if (result.data.success) {
        setPifBalance(result.data.walletBalance);
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {}
  };

  const setVizualiseCallMethod123 = (datasssss, pageNumber) => {
    setTotalPages(Math.ceil(datasssss.length / itemsPerPage));
    indexOfLastItem = pageNumber * itemsPerPage;
    indexOfFirstItem = indexOfLastItem - itemsPerPage;
    setCurrentItems(datasssss.slice(indexOfFirstItem, indexOfLastItem));
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
    setVizualiseCallMethod123(directReferralsDetail, pageNumber);
  };



  const activateIdByPifWallet = (e, id,userAddress) => {
    e.preventDefault();
    setShowModal(true);
    setActivateUserId(id);
    setActivateUserAddress(userAddress)
  };

  const confirmActivateId = async (e) => {
    e.preventDefault();
    try {
      let payload = {
        referralId: activateUserId,
        id:loginDetails.id
      };
      let result = await activateUser(payload,AuthToken);
      if (result.data.success) {
        toast.success(result.data.message);
        fetchDashboardDetails();
        getDirectReferrals();
        fetchPifWalletBalance();
        handleClose();
      } else {
        toast.error(result.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };



  const handleChange = async (value) => {
    setUsdtAmountErrorMessage("");
    setUsdtAmount(value);
  };
  const depositAmountValidation = () => {
    let error = "";

    if (usdtAmount <= 0) {
      error = "Amount must be greater then 0.";
    }

    if(parseFloat(pifBalance)+parseFloat(usdtAmount)>=499){
      error = "For your information, the Pay It Forward wallet can only hold a maximum of USD 500 at any given time. For more information please refer to FAQ";
    }

    if(parseFloat(usdtAmount)>=100){
      error = "For your information, the Pay It Forward wallet can only hold a maximum of USD 500 at any given time. For more information please refer to FAQ";
    }
    if (error.length > 0) {
      setUsdtAmountErrorMessage(error);
      return false;
    }

    
    return true;
  };

  const deposit = async (e) => {
    e.preventDefault();
    try {
      console.log("deposit");
      setLoader(true);

      let validation = depositAmountValidation();

      if (!validation) {
        setLoader(false);

        return;
      }

      let results = await depositInWalletPif(address,usdtAmount,_useWeb3ModalProvider);

      if (results.status) {
        toast.success(results.message);
        handleClose();
        setLoader(false);
      } else {
        toast.error(results.message);
        setLoader(false);
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  };
  return (
    <>
    <Toaster/>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
      {loader && (
        <div className="mainLoader">
          <TailSpin
            visible={true}
            height="80"
            width="80"
            color="#e1b028"
            ariaLabel="tail-spin-loading"
            radius="1"
            wrapperStyle={{}}
            wrapperClass=""
          />
        </div>
      )}
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >


          <Row>
            <Col lg={6} className=""></Col>
            <Col lg={6} className="mb-4">
              <div className="Tablist w-100">
                <div className="d-sm-flex t-list mb-0 justify-content-between w-100 align-items-center">
                  <div className="">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="pr-2 border-right">
                        <h6 className="mb-0">Status</h6>
                      </div>
                      <div className="pl-2">
                        <h5 className="text-primary mb-0">
                          {dashboardDetail.length > 0 &&
                          dashboardDetail[0].status == 1
                            ? "ACTIVE DONOR"
                            : "INACTIVE DONOR"}
                        </h5>
                      </div>
                     
                    </div>
                  </div>

                  {dashboardDetail.length > 0 &&
                    dashboardDetail[0].status == 1 && (
                      <div className=" border-0">
                        <div className="">
                        <p className="text-white my-2">
              PIF wallet Balance : <span className="text-primary"><b> {pifBalance} USDT</b></span>
            </p>
            <div>
                          <Button onClick={e=>{handleOpenPifWalletDeposit(e)}}>Load</Button>
                        </div>
                        
                        </div>
                      </div>
                    )}
                </div>
              </div>
            </Col>
            <Col lg={12} className="">
              <Row>
                <Col lg={12} className="mb-4">
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0">Direct Referral</h5>
                  </div>
                  <Card className="cardBox overflow-hidden sameHeight">
                    <div className="d-table table-responsive">
                      <Table striped hover className="text-center mb-0">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th className="text-uppercase">NAME</th>
                            <th className="text-uppercase">Wallet Address </th>
                            <th className="text-uppercase">Joining Date</th>
                            <th>Status</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {/* {directReferralsDetail.length > 0 &&
                            directReferralsDetail.map((item, index) => ( */}
                             {currentItems.length > 0 &&
                            currentItems.map((item, index) => (
                              <tr>
                                <td>{(currentPage - 1) * itemsPerPage + index + 1}</td>
                                <td>{item.name!=null?item.name:item.userId}</td>
                                <td>{item.address.toString().substring(0,4)}...{item.address.toString().substring(item.address.length-4,item.address.length)}</td>
                                <td>{item.datetime}</td>
                                <td>
                                  {item.status == 1 ? "active" : "Inactive"}
                                </td>
                                <td>
                                  {item.status == 1 ? (
                                    "-"
                                  ) : (
                                    <button
                                    className="btn btn-primary btn-sm"
                                      onClick={(e) => {
                                        activateIdByPifWallet(e, item.id,item.address);
                                      }}
                                    >
                                      Activate
                                    </button>
                                  )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </Card>
                  <div className="mt-3">
                <Pagination className="justify-content-end">
                  <Pagination.First onClick={() => handlePageChange(1)} />
                  <Pagination.Prev
                    onClick={() =>
                      handlePageChange(currentPage > 1 ? currentPage - 1 : 1)
                    }
                  />
                  {/* {[...Array(totalPages)].map((_, idx) => (
                    <Pagination.Item
                      key={idx + 1}
                      active={idx + 1 === currentPage}
                      onClick={() => handlePageChange(idx + 1)}
                    >
                      {idx + 1}
                    </Pagination.Item>
                  ))} */}
                  <Pagination.Item
                    onClick={() => handlePageChange(currentPage)}
                  >
                    {currentPage}
                  </Pagination.Item>
                  <Pagination.Item>...</Pagination.Item>
                  <Pagination.Item onClick={() => handlePageChange(totalPages)}>
                    {totalPages}
                  </Pagination.Item>

                  <Pagination.Next
                    onClick={() =>
                      handlePageChange(
                        currentPage < totalPages ? currentPage + 1 : totalPages
                      )
                    }
                  />
                  <Pagination.Last
                    onClick={() => handlePageChange(totalPages)}
                  />
                </Pagination>
              </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>

      {/* <Modal show={showModal} onHide={handleClose} centered>
          <Modal.Header className="border-0" closeButton>
            <Modal.Title>
            <div className="text-center" >
              <h1>Are You sure you want to activate user {activateUserId}</h1>
              </div></Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-5">
            
            <div className="text-center">
            <Button variant="primary" className="my-3 px-4" onClick={e=>{confirmActivateId(e)}}>Yes</Button>
            </div>
            <div className="text-center">
            <Button variant="primary" className="my-3 px-4" onClick={handleClose}>No</Button>
            </div>
          </Modal.Body>
        </Modal> */}

      <Modal show={showModal} onHide={handleClose} centered className="d-modal">
        <Modal.Header closeButton className="py-2">
          <Modal.Title className="fs-5">Confirm to Activation</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mb-0">
          <div className=" text-center">
            <p className="text-white my-2">
              {/* Are You sure you want to activate user <span className="text-primary"><b>{activateUserId}</b></span> */}
              Are You sure you want to activate user <span className="text-primary"><b>{activateUserAddress.toString().substring(0,4)}...{activateUserAddress.toString().substring(activateUserAddress.length-4,activateUserAddress.length)}</b></span>
            </p>
          </div>
          <Row>
            <Col>
            <div className="text-center">
            <Button
              variant="primary"
              className="my-3 px-4 me-2"
              onClick={(e) => {
                confirmActivateId(e);
              }}
            >
              Yes
            </Button>
            <Button
              variant="primary"
              className="my-3 px-4"
              onClick={handleClose}
            >
              No
            </Button>
          </div>
          </Col>
       
          </Row>
        
        </Modal.Body>
      </Modal>

      <Modal show={showPifWallet} onHide={handleClose} centered>
          <Modal.Header
            className="border-0 border-bottom border-dark"
            closeButton
          >
            <Modal.Title>
              <h5 className="text-center mb-0" style={{ color: "white" }}>
                Deposit in PIF wallet
              </h5>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="py-5">
            <div className="">
              <div className="px-4">
                <p style={{ color: "white" }} className="mb-2">
                  {" "}
                  Please enter USDT amount for deposit
                </p>
                <Form.Control
                  type="number"
                  style={
                    usdtAmountErrorMessage != "" ? { borderColor: "red" } : {}
                  }
                  onChange={(e) => {
                    handleChange(e.target.value);
                  }}
                  value={usdtAmount}
                ></Form.Control>
              </div>
              {usdtAmountErrorMessage != "" && (
                <span style={{ color: "red" }}>{usdtAmountErrorMessage}</span>
              )}
              <div className="px-4"></div>

              
            </div>

            <div className="text-center">
              <Button
                variant="primary"
                className="my-3 px-4"
                onClick={(e) => {
                  deposit(e);
                }}
              >
                Deposit
              </Button>
            </div>
          </Modal.Body>
        </Modal>
    </>
  );
};

export default Spacenetwork;
