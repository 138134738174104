import React, { useState, useMemo, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  InputGroup,
  Button,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import "../css/spacewall.css";
import "../css/spaceverification.css";
import Select from "react-select";
import countryList from "react-select-country-list";
import { flavourOptions } from "../../../data.ts";
import chroma, { random } from "chroma-js";
import {
  getCountryList,
  updateUserDetails,
  getUserDetailById,
} from "../../utils/apiServices.js";
import { useDispatch, useSelector } from "react-redux";
import toast, { Toaster } from "react-hot-toast";
import * as ACTIONTYPES from "../../redux/actionTypes.js";

const Spaceverification = () => {
  const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const AuthToken = useSelector((state) => state.auth.AuthToken);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    countryId: "",
    DOB: "",
    image: null // New field for image

  });
  const options = useMemo(() => countryList().getData(), []);
  let [country, setCountry] = useState([]);
  const [user, setUser] = useState([]);
  const [validationErrors, setValidationErrors] = useState({
    name: "",
    email: "",
    mobile: "",
    countryId: "",
    DOB: "",
    image: "", // New field for image
  });
  const dispatch = useDispatch();

  const currentDate = new Date().toISOString().split("T")[0]; // Get current date in "YYYY-MM-DD" format

  useEffect(() => {
    fetchCountries();
    fetchUserDetail();
  }, []);

  const fetchCountries = async () => {
    let res = await getCountryList({},AuthToken);
    if (res.data.success) {
      let data = res.data.data;
      setCountry(data);
    }
  };

  const fetchUserDetail = async () => {
    let res = await getUserDetailById({ id: loginDetails.id },AuthToken);
    if (res.data.success) {
      let data = res.data.data;
      setFormData(...data);
    }
  };

  const handleChange = (e) => {
    setValidationErrors({
      name: "",
      email: "",
      mobile: "",
      countryId: "",
      DOB: "",
      image: "", // New field for image
    })
    const { name, value, files } = e.target;
    setFormData({
      ...formData,
      [name]: name === "image" ? files[0] : value, // Handle image file differently
    });
  };
  const onSubmit = async () => {
    try {
      const errors = {};
      if (!formData.name) {
        errors.name = "Name is required";
      }
      if (!formData.email) {
        errors.email = "Email is required";
      }
      if (!formData.mobile) {
        errors.mobile = "Phone Number is required";
      }
      if (!formData.DOB) {
        errors.DOB = "Date Of Birth is required";
      }
      if (!formData.countryId) {
        errors.countryId = "Country is required";
      }

      // if (!formData.image) {
      //   errors.image = "Image is required";
      // } else if (!['image/jpeg', 'image/png', 'image/gif'].includes(formData.image.type)) {
      //   errors.image = "Invalid image format. Please upload a JPEG, PNG, or GIF file.";
      // }

      if (Object.keys(errors).length > 0) {
        setValidationErrors(errors);
        return;
      }

      formData.id = loginDetails.id;

      const formData1 = new FormData();
      formData1.append('name', formData.name);
      formData1.append('email', formData.email);
      formData1.append('mobile', formData.mobile);
      formData1.append('DOB', formData.DOB);
      formData1.append('countryId', formData.countryId);
      formData1.append('image', formData.image);
      formData1.append('id', formData.id);

      const res = await updateUserDetails(formData1,AuthToken);
      if (res.data.success) {
        toast.success(res.data.msg);
        dispatch({
          type: ACTIONTYPES.isVerified,
          payload:true,
        });
        fetchUserDetail();
      } else {
        toast.error(res.data.msg);
      }
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <>
      <Toaster />
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            <Col lg={12} className="mb-4 mt-5 pt-5">
              <Row className="justify-content-center">
                <Col lg={7}>
                  <div className="heading mb-3">
                    <h5 className="text-uppercase mb-0 text-primary">
                      Get verified with Personal Information
                    </h5>
                  </div>
                  <Card className="cardBox p-5">
                    <Form>
                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter your name</Form.Label>
                        <Form.Control
                          type="text"
                          placeholder="Name"
                          name="name"
                          onChange={handleChange}
                          value={formData.name}
                          style={{ borderColor: validationErrors.name ? "red" : "" }}
                        />
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.name}
                        </span>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter your email</Form.Label>
                        <Form.Control
                          type="email"
                          placeholder="Email Address"
                          name="email"
                          onChange={handleChange}
                          value={formData.email}
                          style={{ borderColor: validationErrors.email ? "red" : "" }}
                        />{" "}
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.email}
                        </span>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter your contact number</Form.Label>
                        <Form.Control
                          type="number"
                          placeholder="Phone Number"
                          name="mobile"
                          onChange={handleChange}
                          value={formData.mobile}
                          style={{ borderColor: validationErrors.mobile ? "red" : "" }}

                        />
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.mobile}
                        </span>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter your date of birth</Form.Label>
                        <Form.Control
                          type="date"
                          placeholder="Date of Birth"
                          name="DOB"
                          onChange={handleChange}
                          value={formData.DOB}
                          max={currentDate}
                          style={{ borderColor: validationErrors.DOB ? "red" : "" }}

                        />
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.DOB}
                        </span>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Enter your country</Form.Label>
                        <Form.Select
                          aria-label="Default select example"
                          name="countryId"
                          onChange={handleChange}
                          value={formData.countryId}
                          style={{ borderColor: validationErrors.countryId ? "red" : "" }}

                        >
                          <option>Select Country</option>
                          {country.map((item) => (
                            <option key={item.id} value={item.id}>
                              {item.name}
                            </option>
                          ))}
                        </Form.Select>
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.countryId}
                        </span>
                      </Form.Group>

                      <Form.Group
                        className="mb-4"
                        controlId="exampleForm.ControlInput1"
                      >
                        <Form.Label>Upload your image</Form.Label>
                        <Form.Control
                          type="file"
                          name="image"
                         className="uploadImages"
                          onChange={handleChange}
                          style={{color:"#39393a", borderColor: validationErrors.image ? "red" : "" }}

                        />
                        <span className="text-red-500 text-sm" style={{color:"red"}}>
                          {validationErrors.image}
                        </span>
                      </Form.Group>

                      <div className="text-right">
                        <Button
                          variant="primary"
                          className="px-5"
                          onClick={onSubmit}
                        >
                          Submit
                        </Button>
                      </div>
                    </Form>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Box>
      </Box>
    </>
  );
};

export default Spaceverification;
