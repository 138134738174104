import axiosClient from "../../coreFiles/axiosConfig";


export const getAboutImg = (payload, token) => {
  return axiosClient("aboutPageImg", payload, "GET",token);
};
export const getAboutVdo = (payload, token) => {
  return axiosClient("aboutPageVdo", payload, "GET",token);
};
export const getQuestions = (payload, token) => {
  return axiosClient("getQuestions", payload, "GET",token);
};

export const getUserBotQuestionsAndAnswers = (payload, token) => {
  return axiosClient("getUserBotQuestionsAndAnswers", payload, "POST",token);
};

export const insetIntoBotQuestions = (payload, token) => {
  return axiosClient("insetIntoBotQuestions", payload, "POST",token);
};

export const registerUser = (payload, token) => {
  return axiosClient("registerUser", payload, "POST",token);
};

export const dashboardDetails = (payload, token) => {
  return axiosClient("dashboardDetails", payload, "POST",token);
};

export const visualize = (payload, token) => {
  return axiosClient("visualize", payload, "POST",token);
};

export const getMiniVideos = (payload, token) => {
  return axiosClient("getMiniVideos", payload, "GET",token);
};


export const leafletImages = (payload, token) => {
  return axiosClient("leafletImages", payload, "GET",token);
};



export const fetchCountriesApi = (payload, token) => {
  return axiosClient("countries", payload, "GET",token);
};




export const eventsAddApi = (payload, token) => {
  return axiosClient("eventsAdd", payload, "POST",token);
};


export const eventsGetApi = (payload, token) => {
  return axiosClient("eventsGet", payload, "GET",token);
};

export const getDirectReferralsByIdApi = (payload, token) => {
  return axiosClient("getDirectReferralsById", payload, "POST",token);
};

export const activateUser = (payload, token) => {
  return axiosClient("activateUser", payload, "POST",token);
};

export const piWalleBalance = (payload, token) => {
  return axiosClient("piWalleBalance", payload, "POST",token);
};

export const userWithdrawRequest = (payload, token) => {
  return axiosClient("auth/userWithdrawRequest" , payload, "POST", {
    Authorization: token,
  });
};

export const getCountryList = (payload, token) => {
  return axiosClient("getCountryList", payload, "GET",token);
};


export const getUserDetailById = (payload, token) => {
  return axiosClient("getUserDetailById", payload, "GET",token);
};

export const updateUserDetails = (payload, token) => {
  return axiosClient("updateUserDetails", payload, "POST",token);
};

export const getMytransactions = (payload, token) => {
  return axiosClient("getMytransactions", payload, "GET",token);
};

export const getInviteDonations = (payload, token) => {
  return axiosClient("getInviteDonations", payload, "GET",token);
};

export const sendReferralEmail = (payload, token) => {
  return axiosClient("sentSocialEmail", payload, "POST",token);
};


export const getSciptListByUserId = (payload, token) => {
  return axiosClient("getSciptListByUserId", payload, "POST",token);
};


export const getSciptById = (payload, token) => {
  return axiosClient("getSciptById", payload, "POST",token);
};


export const getAllPpts = (payload, token) => {
  return axiosClient("getAllPpts", payload, "GET",token);
};


export const scriptEmailSend = (payload, token) => {
  return axiosClient("scriptEmailSend", payload, "POST",token);
};


export const marketinggifts = (payload, token) => {
  return axiosClient("marketinggifts", payload, "GET",token);
};



export const getSpaceInfoByUserId = (payload, token) => {
  return axiosClient("getSpaceInfoByUserId", payload, "POST",token);
};




export const getAdminAddress = (payload, token) => {
  return axiosClient("getAdminAddress", payload, "GET",token);
};