import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  Table,
  Form,
  InputGroup,
  Button,
  Modal,
} from "react-bootstrap";
import Sidebar from "../Sidebar";
import Box from "@mui/material/Box";
import "../css/spacewall.css";
import "../css/spacewallupload.css";
import "../css/leaflet.css";
import { FaPlus } from "react-icons/fa6";
import { MdOutlineFileDownload } from "react-icons/md";
import { getMiniVideos, leafletPdfDownload } from "../../utils/apiServices";
import toast, { Toaster } from "react-hot-toast";
import config from "../../../coreFiles/config";
import axios from "axios";
import { Zoom } from "react-reveal";
import { useDispatch, useSelector } from "react-redux";

const MiniVideos = () => {
    const AuthToken = useSelector((state) => state.auth.AuthToken);
          const loginDetails = useSelector((state) => state.auth.LoginDetails[0]);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [leafletImage, setLeafletImages] = useState([]);

  useEffect(() => {
    fetchLeafletApi();
  }, []);

  const fetchLeafletApi = async () => {
    console.log("fetchLeafletApi");
    try {
      let result = await getMiniVideos({},AuthToken);
      if (result.data.success) {
        setLeafletImages(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const downloadMethod = async (e, imageName) => {
    e.preventDefault();
    console.log("downloadMethod");
    try {
      const response = await axios.post(
        `${config.ApiURL}leafletPdfDownload`,
        { fileName: imageName },
        {
          responseType: "blob", // Specify responseType as 'blob' to receive binary data
           headers: {
            Authorization: `Bearer ${AuthToken}`, // Replace with your token or other headers
            'Content-Type': 'application/json', // Optional: Specify the content type
          },
        }
      );

      if (response.status === 200) {
        // Create a temporary URL for the PDF blob
        const url = window.URL.createObjectURL(new Blob([response.data]));

        // Create a temporary link element
        const a = document.createElement("a");
        a.href = url;
        a.download = imageName.replace(".jpg", ".pdf"); // Set the download attribute to the PDF file name
        a.click();

        // Clean up by revoking the temporary URL
        window.URL.revokeObjectURL(url);
      } else {
        console.error("Failed to download PDF:", response.statusText);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <Box sx={{ display: "-webkit-box" }} className="dashboard">
        <Sidebar />
        <Box
          component="main"
          sx={{ flexGrow: 1, p: 3 }}
          style={{ marginTop: "74px" }}
          className="text-white mainBox"
        >
          <Row>
            {leafletImage.length > 0 &&
              leafletImage.map((videoData, index) => (
                <Col lg={4} key={index} className="mb-4">
                  <Card className="overflow-hidden">
                    <div className="uploadImage mb-3 overflow-hidden">
                      <div>
                        <video
                          src={`${config.ApiURL}miniVideos/${videoData.fileName}`}
                          controls
                          width="100%"
                          height="auto"
                        />
                      </div>
                    </div>
                    <div className="">
                      <Button
                        variant="outline-primary"
                        className="h-auto ml-1 me-3"
                        onClick={handleShow}
                      >
                        View
                      </Button>
                    </div>
                    <Zoom>
                      <Modal
                        show={show}
                        onHide={handleClose}
                        className="v-Modal"
                        centered
                        backdropClassName="custom-backdrop"
                        contentClassName="custom-modal-content"
                      >
                        <Modal.Header className="border-0"></Modal.Header>
                        <Modal.Body
                          className="border-0"
                          style={{ marginTop: "-32px" }}
                        >
                          <video
                            src={`${config.ApiURL}miniVideos/${videoData.fileName}`}
                            controls
                            width="100%"
                            height="auto"
                          />
                        </Modal.Body>
                      </Modal>
                    </Zoom>
                  </Card>
                </Col>
              ))}
          </Row>
        </Box>
      </Box>
    </>
  );
};

export default MiniVideos;
