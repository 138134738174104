import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import web3Config from './component/Web3/web3Config';
import { polygon, bscTestnet } from 'wagmi/chains'
import store, { persistor } from "./component/redux/storeConfig";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { createWeb3Modal } from '@web3modal/wagmi/react'
import { http, createConfig, WagmiProvider } from 'wagmi';
import { walletConnect, injected, coinbaseWallet, metaMask } from 'wagmi/connectors'


const queryClient = new QueryClient()
const root = ReactDOM.createRoot(document.getElementById('root'));

const projectId = web3Config.projectId;

const metadata = {
  name: 'Web3Modal',
  description: 'Hi5 Space',
  url: 'https://hi5space.com',
  icons: ['https://avatars.githubusercontent.com/u/37784886']
}

const config = createConfig({

  chains: [polygon],
  transports: {
    [polygon.id]: http()
  },
  connectors: [
    walletConnect({ projectId, metadata, showQrModal: false }),
    injected({ shimDisconnect: true }),
    coinbaseWallet({
      appName: metadata.name,
      appLogoUrl: metadata.icons[0]
    }),
  ]
})


createWeb3Modal({
  wagmiConfig: config,
  projectId,
  enableAnalytics: true,
  enableOnramp: true,
  defaultChain: polygon,
  // defaultChain: bscTestnet,

})


root.render(
  <>

    <WagmiProvider config={config}>
      <QueryClientProvider client={queryClient}>
        <Provider store={store}>
          <PersistGate loading={null} persistor={persistor}>
            <App />
          </PersistGate>
        </Provider>
      </QueryClientProvider>
    </WagmiProvider>

  </>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
